<template>
 <v-container style="margin-top: 50px">
   <div class="text-h3" style="margin-bottom: 40px">Got questions? We've got answers!</div>
   <v-expansion-panels v-model="openPanel">
     <v-expansion-panel id="create-game-panel">
       <v-expansion-panel-header class="text-h4">How do I create a game?</v-expansion-panel-header>
       <v-expansion-panel-content>
         <div class="text-body-1 instructions-body">
           <div class="instructions-paragraph">First, you need to decide what categories you want to use for your game. Head on over to <router-link to="/create">Create A Game</router-link> to get started.</div>
           <div class="instructions-paragraph">Once there, you'll see two options in the tabs on top:</div>
           <div class="d-flex justify-center instruction-image">
             <v-img :max-width="maxWidth()" style="border: red solid" :src="require('../assets/instructions/create_tabs.png')"></v-img>
           </div>
           <div class="instructions-paragraph">The "Category Sets" tab contains pre-selected groups of categories that we think make for a fun night. If you'd instead like to choose your own from the full list, head over to the "Individual Categories" tab.</div>
           <div class="instructions-paragraph">Regardless of which option you choose, we recommend selecting three to five categories for your game. Three categories should be enough for a satisfying, quick game (about an hour), while five will be longer without overwhelming your players (about two hours). </div>

           <div class="d-flex justify-center instruction-image">
             <v-img :maxWidth="maxWidth()" :src="require('../assets/instructions/game_type.png')"></v-img>
           </div>

           <div class="instructions-paragraph">Next, select which type of game you want to create. We offer three options:</div>
           <div class="instructions-paragraph">
             <ul>
               <li>DIY - Host the game yourself, either in person or with your own video chat software.</li>
               <li>DIY + Virtual Pub - Host the game yourself, but use our immersive video platform to do it.</li>
               <li>Professional Hosting - We do everything for you, you just show up!</li>
             </ul>
           </div>

           <div class="instructions-paragraph">Finally, add a descriptive name so you can easily find your game later. Then just hit the "Checkout" button and finish your transaction!</div>

           <div class="d-flex justify-center instruction-image">
             <v-img :maxWidth="maxWidth()" style="border: red solid" :src="require('../assets/instructions/checkout.png')"></v-img>
           </div>
         </div>
       </v-expansion-panel-content>
     </v-expansion-panel>
     <v-expansion-panel id="virtual-pub-panel">
       <v-expansion-panel-header class="text-h4">What is the virtual pub?</v-expansion-panel-header>
       <v-expansion-panel-content>
         <div class="text-body-1 instructions-body">
           <div class="instructions-paragraph">The virtual pub is our customized trivia arena. It combines video chat, trivia presentation, and social interactions to create a seamless, immersive experience.</div>
           <div class="d-flex justify-center instruction-image">
             <v-img :max-width="maxWidth()" :src="require('../assets/instructions/virtualpub.png')"></v-img>
           </div>
           <div class="instructions-paragraph">After they join, each team gathers togethers in one of the "Team Rooms". From there, they can talk to each other without anyone else hearing them, but can still listen to the host and see the questions. Users can also send text chat and emoji reactions to everyone playing to keep the competitive banter going!</div>
           <div class="instructions-paragraph">Whichever team member elects themselves the "captain" can enter their answers directly into the page. There's no need to have any other windows open while playing, so everyone can stay totally immersed in the game.</div>

           <div class="d-flex justify-center instruction-image">
             <v-img :maxWidth="maxWidth()" :src="require('../assets/instructions/teamroom.jpeg')"></v-img>
           </div>
         </div>
       </v-expansion-panel-content>
     </v-expansion-panel>
     <v-expansion-panel id="virtual-pub-panel">
       <v-expansion-panel-header class="text-h4">How do I create a virtual pub?</v-expansion-panel-header>
       <v-expansion-panel-content>
         <div class="text-body-1 instructions-body">
           <div class="instructions-paragraph">The first step is to select the "DIY + Virtual Pub" game type when creating your game:</div>
           <div class="d-flex justify-center instruction-image">
             <v-img :max-width="maxWidth()" :src="require('../assets/instructions/game_type.png')"></v-img>
           </div>
           <div class="instructions-paragraph">After you create the game, you'll be taken to the "Manage Games" page, where you can see your newly created game:</div>
           <div class="d-flex justify-center instruction-image">
             <v-img :max-width="maxWidth()" :src="require('../assets/instructions/create_virtual_pub.png')"></v-img>
           </div>
           <div class="instructions-paragraph">Clicking on the "Create Virtual Pub" button will open a modal window to complete the process:</div>
           <div class="d-flex justify-center instruction-image">
             <v-img :max-width="maxWidth()" :src="require('../assets/instructions/virtual_pub_modal.png')"></v-img>
           </div>

           <div class="instructions-paragraph">Our virtual pub experience is built on top of Ohyay, a video chat platform. You'll need to make an account there to allow you to host (players don't have to make an account). Once you have an account, enter the email used into the box and hit create. We'll spin up a new virtual pub and assign some special hosting permissions to your Ohyay account.</div>
         </div>
       </v-expansion-panel-content>
     </v-expansion-panel>
     <v-expansion-panel id="virtual-pub-panel">
       <v-expansion-panel-header class="text-h4">How do I host using the virtual pub?</v-expansion-panel-header>
       <v-expansion-panel-content>
         <div class="text-body-1 instructions-body">
           <div class="instructions-paragraph">Once you've created the virtual pub, you should see something like this on your manage games page:</div>
           <div class="d-flex justify-center instruction-image">
             <v-img :max-width="maxWidth()" :src="require('../assets/instructions/share_virtual_pub.png')"></v-img>
           </div>
           <div class="instructions-paragraph">Clicking on the "Share" button will open a window with a customized invitation to your event that you can send out to all participants. The link in the invitation will take you to the virtual pub space created for your event.</div>
           <div class="instructions-paragraph">We recommend visiting the virtual pub ahead of time to familiarize yourself with the platform. Make sure you log into your Ohyay account before visiting, or you won't have hosting permissions!</div>
           <div class="instructions-paragraph">When you arrive at the pub, you'll see a screen that looks like this:</div>
           <div class="d-flex justify-center instruction-image">
             <v-img :max-width="maxWidth()" :src="require('../assets/instructions/ohyay_hosting.png')"></v-img>
           </div>
           <div class="instructions-paragraph">In a separate browser tab, you should open the "Present" page for your game (see "How do I present my game?" below for more details). Next, you'll need to share this screen with everyone in the virtual pub:</div>
           <div class="d-flex justify-center instruction-image">
             <v-img :max-width="maxWidth()" :src="require('../assets/instructions/share_screen.png')"></v-img>
           </div>
           <div class="instructions-paragraph">Click the dropdown menu next to the screenshare icon, then select the "Crop Share" option and click "Start".</div>
           <div class="d-flex justify-center instruction-image">
             <v-img :max-width="maxWidth()" :src="require('../assets/instructions/screen_share_dropdown.png')"></v-img>
           </div>
           <div class="instructions-paragraph">This will open a browser panel asking you which tab you want to share. Select the tab that contains your "Present" page.</div>
           <div class="instructions-paragraph">When you go back to the Ohyay tab, you'll see a dialog asking you to select the part of the screen to share. Select the center of the page, and hit the "Crop" button.</div>
           <div class="d-flex justify-center instruction-image">
             <v-img :max-width="maxWidth()" :src="require('../assets/instructions/crop.png')"></v-img>
           </div>
           <div class="instructions-paragraph">That's all there is to it! You can now host the game as usual and all participants will be able to see the questions and hear your voice. Good luck!</div>
         </div>
       </v-expansion-panel-content>
     </v-expansion-panel>
     <v-expansion-panel id="present-game-panel">
       <v-expansion-panel-header class="text-h4">How do I present my game?</v-expansion-panel-header>
       <v-expansion-panel-content>
         <div class="text-body-1 instructions-body">
           <div class="instructions-paragraph">When you're ready, navigate to the <router-link to="/manage">Manage Games</router-link> page. This is also where you'll be automatically redirected after creating a game.</div>
           <div class="instructions-paragraph">Here you should see a list of all your currently active games:</div>

           <div class="d-flex justify-center instruction-image">
             <v-img :maxWidth="maxWidth()" :src="require('../assets/instructions/manage.png')"></v-img>
           </div>

           <div class="instructions-paragraph">When you click on the "Present" button, it will open a slideshow page that you can use to present questions one at a time. This page should be displayed publicly to your players. </div>

           <v-alert dense text outlined type="info" color="primary" style="margin-top: 20px">We recommend opening the slideshow in its own full-screen window, then displaying it on a projector (for in-person games), or screen-sharing using your favorite video software (for virtual games).</v-alert>
         </div>
       </v-expansion-panel-content>
     </v-expansion-panel>
     <v-expansion-panel id="test-game-panel">
       <v-expansion-panel-header class="text-h4">Can I test out my game before hosting?</v-expansion-panel-header>
       <v-expansion-panel-content>
         <div class="text-body-1 instructions-body">
           <div class="instructions-paragraph">Please do! We highly recommend getting familiar with the process beforehand.</div>
           <div class="instructions-paragraph">You can present your game, join as a fake team, and score your answers to get comfortable with the tools.</div>
           <v-alert dense text outlined type="warning" style="margin-top: 20px">After you finish testing, you'll need to "reset" the game to delete all of your test data.</v-alert>
           <div class="instructions-paragraph">Once you're done testing, you can navigate to the <router-link to="/manage">Manage Games</router-link> page to reset your game.</div>
           <div class="d-flex justify-center instruction-image">
             <v-img :max-width="maxWidth()" style="border: red solid" :src="require('../assets/instructions/manage.png')"></v-img>
           </div>
           <div class="instructions-paragraph">Clicking the "reset game" link will delete all teams and answers associated with the game. It will also set the game's active category back to round one, so make sure to do this if you advance the round while testing!</div>
         </div>
       </v-expansion-panel-content>
     </v-expansion-panel>
     <v-expansion-panel id="read-questions-panel">
       <v-expansion-panel-header class="text-h4">How quickly should I read questions?</v-expansion-panel-header>
       <v-expansion-panel-content>
         <div class="text-body-1 instructions-body">
           <div class="instructions-paragraph">We recommend that you allow roughly one minute between each question. This should be enough time for players to jot down notes and come up with any first guesses.</div>
           <div class="instructions-paragraph">This suggested timing schedule (20 seconds to read plus 60 seconds to think), is shown in the upper right corner of each question card for your convenience:</div>
           <div class="d-flex justify-center instruction-image">
             <v-img :maxWidth="maxWidth()" :src="require('../assets/instructions/questiontimer.png')"></v-img>
           </div>
           <div class="instructions-paragraph">At the end of the round, players will see a summary screen with all questions, as well as a four-minute timer. They'll have the duration of this timer to come up with and submit their final answers.</div>

           <div class="d-flex justify-center instruction-image">
             <v-img :maxWidth="maxWidth()" :src="require('../assets/instructions/summary.png')"></v-img>
           </div>
           <v-alert dense text outlined type="info" color="primary" style="margin-top: 20px">With this timing, each round should take about fifteen minutes to get through, plus a few more minutes to share the correct answers with teams.</v-alert>
         </div>
       </v-expansion-panel-content>
     </v-expansion-panel>
     <v-expansion-panel id="join-game-panel">
       <v-expansion-panel-header class="text-h4">How do players join the game?</v-expansion-panel-header>
       <v-expansion-panel-content>
         <div class="text-body-1 instructions-body">
           <div class="instructions-paragraph">Each game you create will have its own unique "game code" that your players can use to join the game. This can be seen on the <router-link to="/manage">Manage Games</router-link> page:</div>

           <div class="d-flex justify-center instruction-image">
             <v-img :maxWidth="maxWidth()" :src="require('../assets/instructions/manage.png')"></v-img>
           </div>

           <div class="instructions-paragraph">When it's time for your game to start, you'll need to share this code with your players so that they can join the game. There are two ways to do this, depending on your game type:</div>
           <div class="instructions-paragraph">
             <ol>
               <li>If you're using our virtual pub, you can click on the "Game Code" text to copy the game code. Players will need to enter this into the game form embedded in the virtual pub.</li>
               <li>Alternatively, if you're using our DIY solution, you can copy the unique game link by clicking on "Game Code." Players should navigate to this link in order to join the game.</li>
             </ol>
           </div>

           <v-alert dense text outlined type="warning" color="primary" style="margin-top: 20px">Note that only one person on each team needs to join the game, since they'll be submitting answers for their entire team.</v-alert>
         </div>
       </v-expansion-panel-content>
     </v-expansion-panel>
     <v-expansion-panel id="submit-answers-panel">
       <v-expansion-panel-header class="text-h4">How do I know if teams have submitted answers?</v-expansion-panel-header>
       <v-expansion-panel-content>
         <div class="text-body-1 instructions-body">
           <div class="instructions-paragraph">While presenting your game, you should also have its "Manage" page open somewhere that teams can't see. We recommend also logging in on a smartphone or opening the page in a separate browser window.</div>
           <div class="instructions-paragraph">You can find the "Manage" page by navigating to <router-link to="/manage">Manage Games</router-link>. Here you'll see the "Manage" button for each of your games:</div>

           <div class="d-flex justify-center instruction-image">
             <v-img :maxWidth="maxWidth()" :src="require('../assets/instructions/manage.png')"></v-img>
           </div>

           <div class="instructions-paragraph">When you open this page, it will show all the categories you will be using for this game, as well as the teams participating.</div>
           <div class="instructions-paragraph">For each category, a team's name will show up in green if they have submitted answers, and gray if they haven't:</div>

           <div class="d-flex justify-center instruction-image">
             <v-img :maxWidth="maxWidth()" :src="require('../assets/instructions/submitted.png')"></v-img>
           </div>

           <div class="instructions-paragraph">This page will automatically update for you, so you can see in real time as answers come in. Once every team has submitted answers (or the timer has gone off), the round is officially over!</div>
         </div>
       </v-expansion-panel-content>
     </v-expansion-panel>
     <v-expansion-panel id="share-answers-panel">
       <v-expansion-panel-header class="text-h4">How do I share the correct answers?</v-expansion-panel-header>
       <v-expansion-panel-content>
         <div class="text-body-1 instructions-body">
           <div class="instructions-paragraph">There are two ways to share answers with your players:</div>
           <div class="instructions-paragraph">
             <ul>
               <li>From the "Manage" page for your game, you can open the "Review" link. This will let you read through each question and see the answers, as well as any additional facts that might be fun to share.</li>
               <li>On the "Present" page, the final slide of each category shows a summary of all the questions in the round. Here, you can click each question tile to flip it over and reveal the answer to everyone.</li>
             </ul>
           </div>

           <div class="d-flex justify-center instruction-image">
             <v-img :maxWidth="maxWidth()" :src="require('../assets/instructions/vanna.gif')"></v-img>
           </div>
           <div class="instructions-paragraph">Use whichever of these two options works best for you (or use both!).</div>
           <div class="instructions-paragraph">When you're ready to move on, go to the next slide and click the big "Advance Round" button.</div>

           <div class="d-flex justify-center instruction-image">
             <v-img :maxWidth="maxWidth()" style="border: red solid" :src="require('../assets/instructions/advance.png')"></v-img>
           </div>
         </div>
       </v-expansion-panel-content>
     </v-expansion-panel>
     <v-expansion-panel id="score-answers-panel">
       <v-expansion-panel-header class="text-h4">How do I score submitted answers?</v-expansion-panel-header>
       <v-expansion-panel-content>
         <div class="text-body-1 instructions-body">
           <div class="instructions-paragraph">It's time to find out who won! With our provided tools, scoring is easy and can be done whenever you want. If you have a few minutes of down-time while waiting for answers to come in, that can be a good opportunity to score previous rounds. Or, if that feels too stressful, you can score all rounds at the end of the game.</div>
           <div class="text-h5 subheading">How to Score</div>
           <div class="instructions-paragraph">From your "Manage" page, you should see a list of all categories and whether or not teams have submitted scores:</div>

           <div class="d-flex justify-center instruction-image">
             <v-img :maxWidth="maxWidth()" :src="require('../assets/instructions/submitted.png')"></v-img>
           </div>
           <div class="instructions-paragraph">Once all team names are green (or you've waited long enough), click on the "Score" button to score that round.</div>
           <div class="instructions-paragraph">On the scoring page, you'll see each question, the correct answer, and a list of all answers that were submitted. All you need to do is select the score that you think each answer deserves (half points are up to your discretion).</div>
           <div class="instructions-paragraph">A completed scoring example is seen here:</div>
           <div class="d-flex justify-center instruction-image">
             <v-img :maxWidth="maxWidth()" :src="require('../assets/instructions/scoring.png')"></v-img>
           </div>

           <v-alert dense text outlined type="warning" color="primary" style="margin-top: 20px">Once you're satisfied with your selections, make sure to click the big "Submit Scores" button! Otherwise, your scores won't be registered.</v-alert>
           <div class="instructions-paragraph">If you ever realize that you made a mistake (or a team successfully campaigns for a half point!), you can always adjust scores by clicking on the "Edit Scores" button from the "Manage" page.</div>
           <div class="d-flex justify-center instruction-image">
             <v-img :maxWidth="maxWidth()" :src="require('../assets/instructions/rescore.png')"></v-img>
           </div>
           <div class="instructions-paragraph">That's all there is to it! Once you've scored every round, you can share the results with your players and find out who won.</div>
         </div>
       </v-expansion-panel-content>
     </v-expansion-panel>
     <v-expansion-panel id="who-won-panel">
       <v-expansion-panel-header class="text-h4">How do I know who won?</v-expansion-panel-header>
       <v-expansion-panel-content>
         <div class="text-body-1 instructions-body">
           <div class="instructions-paragraph">Once your players have submitted answers for every round of your game, you can navigate to the final page of your presentation slideshow. Here you'll see a big button to see the final scores:</div>
           <div class="d-flex justify-center instruction-image">
             <v-img :maxWidth="maxWidth()" :src="require('../assets/instructions/view_scores.png')"></v-img>
           </div>
           <div class="instructions-paragraph">Make sure you only click this button after you've finalized all scoring decisions and submitted scores! When you do, it will take you to a summary page showing every team's final score for each round, as well as the total.</div>
         </div>
       </v-expansion-panel-content>
     </v-expansion-panel>
   </v-expansion-panels>
 </v-container>
</template>

<script>
export default {
  name: "FAQ",
  data: () => {
    return {
      link: null,
      openPanel: null
    }
  },
  metaInfo: {
    title: 'FAQ'
  },
  methods: {
    maxWidth() {
      return this.$isMobile ? "275" : "600";
    },
    copyText(link) {
      navigator.clipboard.writeText(window.location.origin + this.$route.fullPath + "#" + link);
    }
  },
  mounted() {
    if (this.$route.params.link) {
      this.link = this.$route.params.link;
      this.$vuetify.goTo(document.getElementById(this.link));
      let panel = Array.from(document.getElementsByClassName("v-expansion-panel")).map(el => el.id).indexOf(this.link);
      if (panel) {
        this.openPanel = panel;
      }
    }
  }
}
</script>

<style scoped>
.instructions-body {
  padding: 5px;
  margin-left: 24px;
}
.instruction-image {
  margin: 40px 20px
}
.instructions-paragraph {
  padding: 5px 0;
}
.subheading {
  margin-top: 20px;
}
.v-expansion-panel {
  margin: 10px 0;
}
</style>